import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

import { Filled } from 'Wrappers/Filled';
import { Squared } from 'Wrappers/Squared';
import { Details } from 'Wrappers/Details';

import { Link } from 'Elements/Link';
import { TextBasic } from 'Elements/TextBasic';
import { ButtonBasicText } from 'Elements/ButtonBasicText';
import { ConfigStore } from 'DataSource/Stores/ConfigStore.js';

const QrCodeContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;
    background-color: #C4C4C4;
    border-radius: 10px;

    svg, img {
        width: 100%;
        height: 100%;
    }
`;

const Downloader = styled.div`
    display: none;
`;

const DownloadButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    & > div:first-child {
        margin-left: 0;
    }
`;

const downloadBlob = (blob, filename) => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => { a.remove()}, 60000);
}


const LinkInfo = (props) => {

    const {
        title,
        tail,
    } = props;

    let imageRefPng = null;
    let imageRefSvg = null;

    const fullLinkUrl = ConfigStore.config.BASE_LINK_URL + '/' + tail +'/';

    const onClickDownloadPng = () => {

        imageRefPng.children[0].toBlob(
            (blob) => downloadBlob(blob, `qr-code ${title}.png`)
        );
    }

    const onClickDownloadSvg = () => {

        var content = imageRefSvg.innerHTML;
        // there no xmlns attribute so add it
        content = content.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');

        downloadBlob(new Blob([content], {type: 'image/svg+xml'}), `qr-code ${title}.svg`);
    }

    /**
     *
     */
    return (

        <>
            <Downloader ref={ e => { imageRefPng = e }}>
                <QRCode
                    value={fullLinkUrl}
                    renderAs="png"
                    size={200}
                    bgColor="#fff"
                />
            </Downloader>

            <Downloader ref={ e => { imageRefSvg = e }}>
                <QRCode
                    value={fullLinkUrl}
                    renderAs="svg"
                    size={200}
                    bgColor="#fff"
                />
            </Downloader>

            <Filled style={{ marginTop: '16px' }}>
                <Squared>
                    <QrCodeContainer>
                        <QRCode
                            value={fullLinkUrl}
                            renderAs="svg"
                            bgColor="#c4c4c4"
                        />
                    </QrCodeContainer>
                </Squared>
                <Details>
                    <TextBasic>
                        {title}
                    </TextBasic>
                    <Link to={fullLinkUrl}>
                        {fullLinkUrl}
                    </Link>
                    <DownloadButtonsContainer>
                        <ButtonBasicText onClick={onClickDownloadPng}>
                            Download QR-code (.png)
                        </ButtonBasicText>
                        <ButtonBasicText onClick={onClickDownloadSvg}>
                            Download QR-code (.svg)
                        </ButtonBasicText>
                    </DownloadButtonsContainer>
                </Details>
            </Filled>
        </>
    );
}

export { LinkInfo }
